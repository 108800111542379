<template>
  <!--
    Displays a backlog of used commands.
  -->
  <div class="auditCommandLog">
    <LoadingPlaceholder v-if="loadingAudit" />

    <template v-else-if="auditLogs">
      <Portlet
        :title="$t('auditCommandLogComponent.commands')"
        icon="history"
      >
        <div style="max-height: 350px; overflow-y: auto; width: 100%;">
          <table
            v-if="auditLogCommands != null"
            class="defaultTable"
          >
            <colgroup>
              <col
                class="hide-down-sm"
                width="130"
              >
              <col
                class="hide-down-sm"
                width="250"
              >
              <col class="hide-down-md">
              <col>
            </colgroup>
            <thead>
              <tr class="border-bottom-0">
                <th class="hide-down-sm border-bottom">
                  {{ $t('createDate') }}
                </th>
                <th class="hide-down-sm border-bottom">
                  {{ $t('deviceDetailComponent.createdBy') }}
                </th>
                <th class="hide-down-md border-bottom">
                  {{ $t('deviceDetailComponent.command') }}
                </th>
                <th class="border-bottom">
                  {{ $t('deviceDetailComponent.arguments') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(log, logIndex) in auditLogCommands"
                :key="`deviceDetailAuditLogCommandsTableRow-${ logIndex }`"
              >
                <td
                  v-tooltip="dateTime_fromNow(log.created_at)"
                  class="hide-down-sm"
                >
                  {{ dateTime_dateTime(log.created_at) }}
                </td>
                <td>{{ log.created_by }}</td>
                <td
                  colspan="2"
                  class="p-0 m-0 border-right-0"
                >
                  <table class="defaultTable border-0">
                    <tbody>
                      <tr
                        v-for="(action, actionIndex) in log.actions"
                        :key="`deviceDetailLogCommandTableRow-${ actionIndex }`"
                      >
                        <td class="hide-down-md">
                          {{ action.command }}
                        </td>
                        <td class="border-right-0">
                          {{ action.args }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else>
            {{ $t('noDataAvailable') }}
          </p>
        </div>
      </Portlet>
    </template>

    <p v-else>
      {{ $t('noDataAvailable') }}
    </p>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: 'AuditCommandLog',
  mixins: [
    dateTimeMixin
  ],
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loadingAudit: true,
      auditLogs: null,
    }
  },
  computed: {
    auditLogCommands () {
      if (this.auditLogs == null) {
        return [];
      }
      if (this.auditLogs.length == 0) {
        return [];
      }
      let commands = [];
      this.auditLogs.forEach(auditLog => {
        if (auditLog.action === "command") {
          commands.push(auditLog);
        }
      });
      return commands.length > 0 ? commands : null;
    },
    auditLogChanges () {
      if (this.auditLogs == null) {
        return [];
      }
      if (this.auditLogs.length == 0) {
        return [];
      }
      let changes = [];
      this.auditLogs.forEach(auditLog => {
        if (auditLog.action === "change") {
          changes.push(auditLog);
        }
      });
      return changes.length > 0 ? changes : null;
    }
  },
  created () {
    this.getAuditLogs();
  },
  methods: {
    getAuditLogs () {
      this.loadingAudit = true;
      this.axios.get('/CentralDeviceManagement/GetAuditLog?deviceUuid=' + this.deviceId)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.auditLogs = response.data;
        })
        .finally(() => {
          this.loadingAudit = false;
        });
    }
  }
}
</script>

<style>
thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: -1px;
  background: #f5f5f5 !important;
}
pre {
  white-space: pre-line;
}
td {
  vertical-align: top;
}
</style>
